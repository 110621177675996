.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.europe {
  position: relative;
}
.place-data {
  padding: 4px 16px;
  opacity: 0.75;
  background: lightgreen;
  position: absolute;
  width: auto;
  top: 0;
  height: auto;

}

#root {
  padding: 0 10px;
}

h1 {
  font-size: 35px;
  color: #26d34f;
  margin: 10px;
}

h2 {
  font-size: 15px;
}

.inline-block {
  display: inline-block;
}

 {
  margin-left: 10px;
}

svg {
  border: 1px solid darkgray;
}

.chloropleth-country {
  cursor: pointer;
}

footer {
  margin: 20px;
}

.position-relative {
  position: relative;
}
